/* eslint-disable no-undef */
import React, { Fragment, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  checkLoggedIn,
  getDeliveryCharges,
  mergeClasses,
  totalCartPrice,
} from "../../utils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CustomPhoneInput from "../Input/CustomPhoneInput";
import AddressCard from "../Cards/AddressCard";
import axios from "axios";
import { useEffect } from "react";
import { PulseLoader } from "react-spinners";
import {
  GetCountries,
  GetState,
  GetCity, //async functions
} from "react-country-state-city";
import ReactSelect from "../UI/Select/ReactSelect";
import AddressesSection from "../AddressesSection";
import _ from "lodash";
import { useSelector } from "react-redux";
import { fCurrency } from "../../utils/formatNumber";
import { useCart } from "../../Context/CartContext";

// import ReactSelect from "react-select";

const CheckoutSection = ({ cartList, updateConfigs, token, configs, ...props }) => {

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("visaMastercard");
  const [iframeUrl, setIframeUrl] = useState(null);

  const push = useNavigate();
  const [tabs, setExpanded] = useState({ cupon: false });
  const { cart } = useCart();
  const delivery_charges = useSelector((state) => state?.configs?.admin_conditions?.delivery_charges);
  const loggedInCartPrice = useSelector((state) => state?.cartList?.totalPrice);
  const totalPrice = checkLoggedIn() ? loggedInCartPrice || 0 : (carts?.length > 0 ? totalCartPrice(carts) : 0) + delivery_charges || 0;

  const [placeOrderLoading, setPlaceOrderLoading] = useState("");

  const handleCreateOrder = async () => {
    if (_?.isEmpty(configs?.selectedAddress)) {
      toast.error("Select Address");
      return;
    }
    setPlaceOrderLoading(true);
    if(selectedPaymentMethod==="visaMastercard"){
    await axios
      .post(
        "create-order",
        { addressid: configs?.selectedAddress?._id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res?.data?.status === "ok") {
          handlePayment(res?.data);
        } else {
          toast.error(res?.data?.message);
        }
        setPlaceOrderLoading(false);
      })
      .catch((err) => {
        // toast.error(err?.response?.data?.message || "Something Went Wrong!");
        setPlaceOrderLoading(false);
      });
    }else{

      await axios
      .post(
        "create-order-check",
        { addressid: configs?.selectedAddress?._id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res?.data?.status === "ok") {
         console.log({dataaa:res.data})
    let merchantdata={
     email:res.data.email,
    orderid:res.data.order,
    amount:res.data.totalPrice,
    Sha512Hash:res.data.Sha512Hash
   }
console.log({merchantdata})
          setIframeUrl(res.data.payment_url)

        } else {
          toast.error(res?.data?.message);
        }
        setPlaceOrderLoading(false);
      })
      .catch((err) => {
        // toast.error(err?.response?.data?.message || "Something Went Wrong!");
        setPlaceOrderLoading(false);
      });

    }
  };
  function handlePayment(data) {
    if (typeof Neo !== "undefined") {
      const options = {
        channelId: "WEB",
        customerId: data?.userid,
        merchantType: "ECOMMERCE",
        merchantId: "6363397438889",
        orderID: data?.order,
        orderDescription: "Purchasing Product",
        orderAmount: data?.totalPrice,
        orderCurrency: "USD",
        email: data?.email,
        // countrycode: "971",
        // mobilenumber: "0000000000",
        name: data?.email,
        successHandler: async function (response) {
          // Handle success response
          // window.location.href = "https://example.com/ep/success.html";
          push(`/success/${data?.order}`);
        },
        failedHandler: async function (response) {
          // Handle failure response
          // window.location.href = "https://example.com/ep/failed.html";
          push(`/failed/${data?.order}`);
        },
      };
      const neo = new Neo(options);
      neo.open(options);
    }
  }


  const handlePaymentChange = (event) => {
    console.log({event:event.target.value})
    setSelectedPaymentMethod(event.target.value);
  };

  return (
    <Fragment>
      <div className="checkout-area sm-separator-padding">
      {!iframeUrl &&(<div className="sm-container">
          <div className="checkout-wrap">
            <div className="row">
              <div className="col-lg-6 col-12">
                <AddressesSection />
              </div>

              <div className="col-lg-6 col-12">
                <div className="checkout-order-area">
                  <h3>Your Order</h3>
                  <div className="oreder-item">
                    <div className="title bg-title">
                      <h2>
                        Products <span>Subtotal</span>
                      </h2>
                    </div>
                    {cartList?.map((item) => (
                      <div className="oreder-product" key={item.id}>
                        <div className="images">
                          <span className="mr-10">
                            <img src={item?.proimg?.[0]?.src} alt="" />
                          </span>
                          <small>
                            {item.title} ${fCurrency(item.price)} x {item.qty}
                          </small>
                        </div>
                        <span> ${fCurrency(item.qty * item.price)}</span>
                      </div>
                    ))}

                    <div className="title s1">
                      <h2>
                        Delivery Charge<span>${getDeliveryCharges()}</span>
                      </h2>
                    </div>
                    <div className="title s1">
                      <h2>
                        Subtotal
                        <span>${fCurrency(totalPrice - Number(configs?.admin_conditions?.delivery_charges))}</span>
                      </h2>
                    </div>
                    <div className="title s2">
                      <h2>
                        Total
                        <span>${fCurrency(totalPrice)}</span>
                      </h2>
                    </div>
                  </div>
                  <div className="payment-option">
                    <h3>Payment Method</h3>
                    <div className="payment-select">
                      <ul>
                        <li className="addToggle">
                          <input id="add" type="radio" name="payment" 
                             checked={selectedPaymentMethod === "visaMastercard"}
                             onChange={handlePaymentChange}
                           value="visaMastercard" />
                          <label for="add">Visa/Mastercard Credit Card</label>
                        </li>
                        <li className="removeToggle">
                          <input id="remove" type="radio" name="payment"
                              checked={selectedPaymentMethod === "checkDeposit"}
                              onChange={handlePaymentChange}
                          value="checkDeposit" />
                          <label for="remove">Check payments</label>
                        </li>
                      </ul>
                    </div>
   
                 
                    <div className="payment-bottom">
                      <p>
                        Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described
                        in our privacy policy.
                      </p>

                      <div className="payment-btn">
                        <button className="btn-style-1" style={{ width: "100%" }} onClick={handleCreateOrder} disabled={placeOrderLoading || cart?.loading}>
                          {placeOrderLoading || cart?.loading ? <PulseLoader size={10} color={"#fafafa"} /> : "Place order"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>)}


        {iframeUrl && (
        <div className="m-auto w-10/12">
          <h3 className="text-lg font-medium text-gray-800 mb-2">Check Deposit</h3>
          <iframe
            src={iframeUrl}
            title="Check Deposit"
            className="w-full h-900 border rounded-md"
          />
        </div>
      )}


      </div>
    </Fragment>
  );
};

export default CheckoutSection;
